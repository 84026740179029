<template>
  <div class="mobile-width centered p-5">
    <b-message :type="type">
      {{ text }}
    </b-message>
  </div>
</template>

<script>
export default {
  name: 'Message',
  props: {
    text: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'is-info',
    },
  },
}
</script>

<style lang="scss" scoped></style>

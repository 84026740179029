<template>
  <div>
    <div v-if="!loading" class="container">
      <div
        v-if="order && user"
        class="section centered"
        style="width: fit-content;block-size: fit-content;"
      >
        <!-- coin logo and name -->
        <div class="crypto-order-logo mb-5">
          <crypto-logo
            :ticker="order.currencyGiven.ticker"
            :name="order.currencyGiven.name"
            :url="order.currencyGiven.logoURL"
          >
          </crypto-logo>
          <h1 class="title is-5">
            {{
              order.type === 'sell'
                ? $t('pages.sell.title')
                : $t('pages.buy.title')
            }}
            {{ order.currencyGiven.name }} ({{ order.currencyGiven.ticker }})
          </h1>
        </div>
        <!-- order data -->
        <div class="content">
          <div v-if="user.verified">
            <div class="box">
              <div class="columns">
                <span
                  class="column is-half"
                  :class="{ 'has-text-right': !isMobile }"
                  >{{ $t('labels.marketPrice') }}
                  {{ order.currencyGiven.ticker }}:</span
                >
                <span class="column has-text-weight-semibold"
                  >{{ getRoundedNumber(order.marketPrice) }}
                  {{ $t('currency.BGN') }}</span
                >
              </div>
              <div class="columns" v-if="order.blockchainTax">
                <span
                  class="column is-half"
                  :class="{ 'has-text-right': !isMobile }"
                  >{{ $t('labels.blockchainTax') }}:</span
                >
                <span class="column has-text-weight-semibold"
                  >{{ getRoundedNumber(order.blockchainTax) }}
                  {{ $t('currency.BGN') }}</span
                >
              </div>
              <div class="columns">
                <span
                  class="column is-half"
                  :class="{ 'has-text-right': !isMobile }"
                  >{{ $t('labels.tax') }}:</span
                >
                <span class="column has-text-weight-semibold"
                  >{{ getRoundedNumber(order.tax) }}
                  {{ $t('currency.BGN') }}</span
                >
              </div>
              <div class="columns" v-if="order.easyPayTax">
                <span
                  class="column is-half"
                  :class="{ 'has-text-right': !isMobile }"
                  >{{ $t('labels.easyPayTax') }}:</span
                >
                <span class="column has-text-weight-semibold"
                  >{{ getRoundedNumber(order.easyPayTax) }}
                  {{ $t('currency.BGN') }}</span
                >
              </div>
              <div class="columns">
                <span
                  class="column is-half"
                  :class="{ 'has-text-right': !isMobile }"
                  >{{ $t('labels.amount') }}:</span
                >
                <span class="column has-text-weight-semibold">
                  {{ order.amountGiven }} {{ order.currencyGiven.ticker }}
                </span>
              </div>
              <div class="columns">
                <span
                  class="column is-half"
                  :class="{ 'has-text-right': !isMobile }"
                  >{{ paymentMethodLabel }}:</span
                >
                <span class="column has-text-weight-semibold">
                  {{ order.paymentMethod.v }}
                </span>
              </div>
              <div v-if="order.type === 'buy'" class="columns">
                <span
                  class="column is-half"
                  :class="{ 'has-text-right': !isMobile }"
                  >{{ $t('labels.cryptoAddress') }}:</span
                >
                <span
                  class="column has-text-weight-semibold"
                  style="word-break:break-all;"
                >
                  {{ order.addressGiven }}
                </span>
              </div>
              <div
                v-if="
                  order.type === 'sell' &&
                    order.paymentMethod.id !== 'easyPayLocation'
                "
                class="columns"
              >
                <span
                  class="column is-half"
                  :class="{ 'has-text-right': !isMobile }"
                  >{{ $t('labels.payoutAddress') }}:</span
                >
                <span class="column has-text-weight-semibold">
                  {{ this.order.receiverAccountId }}
                </span>
              </div>
              <div class="columns">
                <span
                  class="column is-half"
                  :class="{ 'has-text-right': !isMobile }"
                  >{{ totalSumLabel }}:</span
                >
                <span class="column has-text-weight-semibold">
                  {{ getRoundedNumber(order.amountReceived) }}
                  {{ $t('currency.BGN') }}</span
                >
              </div>
            </div>
            <p class="mt-4">
              {{ confirmMessage }}
            </p>
            <b-button
              type="is-dark"
              size="is-medium"
              icon-right="arrow-right"
              expanded
              class="mt-4"
              :disabled="processing"
              @click="handleContinue"
              >{{ $t('buttons.confirm') }}</b-button
            >
            <p class="is-size-8 mt-3">
              *{{ $t('pages.buy.proceedToPayment') }}
            </p>
          </div>
          <div v-else class="please-verify-link">
            <router-link v-if="isBrowser" to="/account/verify/web">{{
              $t('pages.pleaseVerify')
            }}</router-link>
            <router-link v-if="!isBrowser" to="/account/verify/mobile">{{
              $t('pages.pleaseVerify')
            }}</router-link>
          </div>
        </div>
      </div>
      <div v-else>
        <Message
          v-if="!user"
          :text="$t('errors.unauthenticate')"
          type="is-danger"
        />
        <Message v-if="!order" :text="$t('errors.noNewOrder')" type="is-info" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueBreakpointMixin from 'vue-breakpoint-mixin'
import numbersMixin from '@/mixins/numbers-mixin'
import CryptoLogo from '@/components/common/CryptoLogo'
import Message from '@/components/common/Message'

export default {
  name: 'Order',
  components: { CryptoLogo, Message },
  mixins: [numbersMixin, VueBreakpointMixin],
  data() {
    return {
      loading: false,
      processing: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      order: 'orders/newOrder',
    }),
    isBrowser() {
      let isBrowser = true
      if (global.device && global.device.platform) {
        isBrowser = global.device.platform === 'browser'
      }
      return isBrowser
    },
    paymentMethodLabel() {
      return this.order?.type === 'buy'
        ? this.$t('pages.buy.paymentMethod')
        : this.$t('pages.sell.paymentMethod')
    },
    totalSumLabel() {
      return this.order?.type === 'buy'
        ? this.$t('labels.endTotalBGNToPay')
        : this.$t('labels.endTotalBGNToReceive')
    },
    confirmMessage() {
      return this.order?.type === 'buy'
        ? this.$t('pages.buy.confirmMessage')
        : this.$t('pages.sell.confirmMessage')
    },
    proceedMessage() {
      return this.order?.type === 'buy'
        ? this.$t('pages.buy.proceedToPayment')
        : this.$t('pages.sell.proceedToPayment')
    },
  },
  async created() {
    try {
      this.loading = true
      await this.getProfile()
    } catch (error) {
      this.logout()
      this.$buefy.toast.open({
        duration: 3000,
        message: this.$t('errors.unauthenticate'),
        position: 'is-bottom',
        type: 'is-danger',
      })
    } finally {
      this.loading = false
    }
  },
  methods: {
    ...mapActions({
      getProfile: 'user/getProfile',
      logout: 'auth/logout',
      sellOrder: 'orders/sellOrder',
      buyOrder: 'orders/buyOrder',
    }),
    async handleContinue() {
      if (!this.user || !this.order) {
        return
      }

      try {
        this.processing = true
        const { type, ...rest } = this.order
        const payload = {
          ...rest,
          paymentMethod: this.order.paymentMethod?.id,
          // setup given/received depends on sell/buy
          currencyGiven:
            this.order.type === 'sell'
              ? this.order.currencyGiven?.ticker
              : 'bgn',
          currencyReceived:
            this.order.type === 'sell'
              ? 'bgn'
              : this.order.currencyGiven?.ticker,
          amountGiven:
            this.order.type === 'sell'
              ? this.order.amountGiven
              : this.order.amountReceived,
          amountReceived:
            this.order.type === 'sell'
              ? this.order.amountReceived
              : this.order.amountGiven,
        }
        const action =
          this.order.type === 'sell' ? this.sellOrder : this.buyOrder
        await action(payload)
        this.$buefy.toast.open({
          duration: 17000,
          message: this.$t('dialogs.messages.thanksForOrder'),
          position: 'is-bottom',
          type: 'is-primary',
        })
        // this.$buefy.notification.open({
        //   message: this.$t('dialogs.messages.thanksForOrder'),
        //   duration: 70000,
        //   position: 'is-bottom',
        //   type: 'is-success',
        //   hasIcon: true,
        //   iconPack: 'fas',
        //   iconSize: 'is-large',
        // })
        this.$router.push('/')
      } catch (error) {
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t('errors.somethingWrong'),
          position: 'is-bottom',
          type: 'is-danger',
        })
      } finally {
        this.processing = false
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
